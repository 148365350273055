.navbar + .container
{
margin-top: 50px;
}

.jqplot-highlighter-tooltip
{
background-color: rgba(208, 208, 208, 0.8);
}
td
{
padding: 4px;
}
.transactions
{
font-size: 10px;
}
.transactions td
{
  -webkit-transition: background-color .5s linear;
       -o-transition: background-color .5s linear;
          transition: background-color .5s linear;
}
.transactions.table > thead > tr > td
{
padding-top: 0;
padding-bottom: 0;
}
.transactions label
{
font-size: 12px;
margin-bottom: 0;
}
.lazy.chart
{
height: 100px;
}
.risico
{
display: inline-block;
width: 1.5em;
height: 1.5em;
border-radius: 50%;
color: black;
background-color: rgb(208,208,208);
text-align: center;
}
.risico1
{
color: white;
background-color: rgb(0,100,0);
}
.risico2
{
color: white;
background-color: rgb(90,180,0);
}
.risico3
{
color: white;
background-color: rgb(150,190,0);
}
.risico4
{
color: black;
background-color: rgb(215,215,0);
}
.risico5
{
color: black;
background-color: rgb(215,150,0);
}
.risico6
{
color: white;
background-color: rgb(215,90,0);
}
.risico7
{
color: white;
background-color: rgb(215,0,0);
}
.editable-click,a.editable-click{
text-decoration: inherit;
color: inherit;
border-bottom: dashed 1px transparent;
}
.nav, .pagination, .carousel, .panel-title a { cursor: pointer; }
.form-inline .input-group input.form-control[type="date"]{
width: 7em;
}
input.form-control[type="number"]{
max-width: 7em;
}
.info_block-toggle
{
display: inline-block;
width: 1.5em;
height: 1.5em;
border-radius: 50%;
border: 1px solid red;
text-align: center;
color: red;
font-weigth: bold;
}
.info_block-toggle.open ~ [info-block]
{
display: inherit;
}
[info-block]
{
display: none;
}
@keyframes shake {
  0% {transform: translateX(0);}
  12.5% {transform: translateX(-6px) rotateY(-5deg)}
  37.5% {transform: translateX(5px) rotateY(4deg)}
  62.5% {transform: translateX(-3px) rotateY(-2deg)}
  87.5% {transform: translateX(2px) rotateY(1deg)}
  100% {transform: translateX(0)}
}

.shake {
  animation: shake 400ms ease-in-out;
}
.notes th {
position: relative;
}
.notes th form {
position: absolute;
top: 0;
right: 0;
}
.notes th form input.form-control {
    width: 50em;
}
